<!-- eslint-disable import/no-extraneous-dependencies -->
<script>
import { mapGetters } from 'vuex';
import PaymentSummaryTable from '@/components/PaymentSummaryTable.vue';

export default {
  name: 'se-checkout',
  props: {
    isPaying: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      paymentMethod: {},
      mobileNumber: '',
      errorStatus: undefined,
      attemptSubmit: false,
      attemptSubmitNoPaymentMethod: false,
      isCustoms: null,
      applePayCheckout: null,
      isApplePayMounted: false,
      isImagesModalOpen: false,
    };
  },

  watch: {

  },

  created() {
    if (this.$route.query.error) {
      this.errorStatus = {};
      this.errorStatus.title = this.$route.query.error;
      this.errorStatus.description = 'We have a conflict';
    }

    this.isCustoms = !!this.item.customsId;
  },

  computed: {
    ...mapGetters('shipment', ['item']),
    ...mapGetters('topbar', ['market', 'language']),

    fees() {
      const fees = [
        {
          name: '',
          rows: [
            {
              type: 'VAT',
              amount: this.getFeeAmount('importVatFee'),
            },
            {
              type: 'HANDLING_FEE_TOTAL',
              amount: `${this.getFeeAmount('handlingWithVatFee')} ${this.item.fee.handlingWithVatFee.currency || 'kr'} ${this.$t('INCLUDING_VAT', { vat: `${this.getFeeAmount('handlingVatFee')} ${this.item.fee.handlingVatFee.currency || 'kr'}` })}`,
            },
          ],
        },
      ];

      if (this.isCustoms) {
        const customsFees = {
          type: 'CUSTOMS_FEE',
          amount: this.getFeeAmount('customsFee'),
        };
        fees[0].rows.splice(1, 0, customsFees);
      }

      return fees;
    },
  },

  methods: {
    initPayment(paymentStateData = undefined) {
      this.errorStatus = undefined;
      this.attemptSubmit = true;
      let phoneNumber;

      const data = {
        request: {
          id: this.item.id,
          code: this.item.code,
          customer: {
            email: process.env.VUE_APP_NOPS_EMAIL,
            telephoneNo: phoneNumber,
          },
          platform: 'web',
          language: this.language === 'sv' ? 'sv_SE' : 'en_US',
          paymentStateData,
        },
      };

      this.$emit('onInitPayment', data);
    },

    openModal() {
      this.isImagesModalOpen = true;
    },

    getFeeAmount(type) {
      return this.item.fee[type].amount || 0;
    },

  },

  components: {
    PaymentSummaryTable,
  },
};
</script>

<template>
  <div>
    <pn-toast
      class="prepaid-vat-information"
      v-if="item.iossAcceptedAt || item.pddpAcceptedAt"
      icon="alert-info-circle"
      closable="false"
    >
      <span v-html="$t('CUSTOMS_PAYMENT_PREVIOUS_PREPAID_VAT')" />
    </pn-toast>

    <section v-if="errorStatus">
      <slot />
    </section>

    <slot />

    <section>
      <payment-summary-table
        :header="$t('SHIPMENT_ID')"
        :reference="item.itemId"
        :customs-header="$t('CUSTOMS_ID')"
        :customs-reference="item.customsId"
        :is-customs="isCustoms"
        :sections="fees"
        :total="item.fee.totalFee"
        v-if="item"
      />
    </section>
    <section>
      <div class="shipment-actions">
        <a
          class="link"
          v-if="item.images && item.images.length > 0"
          @click="openModal()"
        >{{ $t('SHOW_IMAGES_OF_PARCEL') }}</a>
      </div>
    </section>

    <pn-toast
      v-if="isCustoms"
      icon="alert-info-circle"
      closable="false"
    >
      {{ $t('CUSTOMS_PAYMENT_INFO_BOX')}}
    </pn-toast>

    <pn-button
      class='pn-vat-button'
      :loading="isPaying"
      @click="initPayment()"
    >
      {{ $t('PAY') }}
    </pn-button>

    <div id="apple-pay-container" />

    <a class="customs-representative" :href="$t('CUSTOMS_REPRESENTATIVE_LINK')" target="_blank" rel='noopener noreferrer' v-if="!isCustoms">
      {{ $t('CUSTOMS_REPRESENTATIVE_LINK_TEXT') }}
    </a>

    <section class="disclaimer">
      <p v-html="$t('DISCLAIMER_FIRST_PAYMENT')" />
      <p v-html="$t('DISCLAIMER_SECOND_PAYMENT')" v-if="!isCustoms" />
    </section>

    <pn-modal
      class='pn-vat-modal'
      :open="this.isImagesModalOpen"
      @close="() => this.isImagesModalOpen = false"
      id="images-modal"
    >
      <h1>{{$t('IMAGES_OF_PARCEL')}}</h1>
      <div class="image" v-for="(image, i) in item.images" :key="i">
        <img :src="image" alt="parcel" />
      </div>
    </pn-modal>
  </div>
</template>

<style lang="scss" scoped>
.pay-button {
  margin-bottom: 1px;
}

.error-text {
  color: $warning;
  font-size: 1.4rem;
  width: 100%;
  margin-left: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.customs-representative {
  display: block;
  text-align: center;
  margin: 20px 0 40px;
  text-decoration: underline;
}

.image {
  width: 100%;
  margin-bottom: 20px;
  > img {
    width: 100%;
  }
}
.shipment-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  a {
    margin-top: 1.6rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>
